/* eslint-disable no-nested-ternary */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import CloseButton from '@components/button/close-button'

import { DrawerSize } from '@constants/index'

type Props = {
  title?: React.ReactNode
  isOpen: boolean
  children: ReactNode
  onClose?: () => void
  size?: DrawerSize
  articleClassName?: string
  sectionClassName?: string
  hideCloseButton?: boolean
  titleContainerClassName?: string
  hideTitle?: boolean
}

const Drawer = ({
  title,
  isOpen,
  children,
  onClose,
  size = DrawerSize.SMALL,
  articleClassName,
  sectionClassName,
  hideCloseButton = false,
  titleContainerClassName,
  hideTitle = false,
}: Props) => (
  <div
    className={classNames(
      'fixed inset-0 z-50 transform overflow-hidden ease-in-out',
      isOpen ? 'translate-x-0' : 'translate-x-full delay-500'
    )}
    aria-label="close drawer"
  >
    <div
      aria-label="close drawer"
      tabIndex={0}
      onKeyDown={onClose}
      role="button"
      className="fixed inset-0 z-50 h-full w-screen cursor-pointer bg-gray-900 bg-opacity-25"
      onClick={onClose}
    />
    <section
      className={classNames(
        'delay-400 fixed right-0 z-[51] h-full w-screen transform bg-white shadow-xl transition-all duration-500 ease-in-out',
        isOpen ? 'translate-x-0' : 'translate-x-full',
        size === DrawerSize.SMALL
          ? 'max-w-lg'
          : DrawerSize.MEDIUM
            ? 'max-w-3xl'
            : '',
        sectionClassName
      )}
    >
      <article
        className={classNames(
          'scrollbar-thin',
          'scrollbar-track-blue-100',
          'scrollbar-thumb-blue-500',
          'hover:scrollbar-thumb-blue-700',
          'relative',
          'p-4',
          'flex',
          'flex-col',
          'space-y-4',
          'overflow-y-scroll',
          'h-full',
          articleClassName
        )}
      >
        {!hideTitle ? (
          title instanceof String ? (
            <div className="flex w-full items-center justify-between p-4">
              {title instanceof String ? (
                <header className="text-xl font-medium">{title}</header>
              ) : (
                title
              )}
              {!hideCloseButton && <CloseButton onClick={onClose} />}
            </div>
          ) : (
            <div
              className={classNames(
                'flex justify-between mr-4',
                titleContainerClassName
              )}
            >
              {title}
              {!hideCloseButton && <CloseButton onClick={onClose} />}
            </div>
          )
        ) : null}
        {children}
      </article>
    </section>
  </div>
)

export default Drawer
